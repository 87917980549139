<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
     <div class="text-center">
    
    </div>
      <div class="m-2">
        <b-row class="mb-1">
        <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-5 mb-md-0"
            >
              <label>Show</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                v-model="perPage"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col> 
        </b-row>
        <!-- Table Top -->
          <b-row>
          <!-- Per Page -->

          <b-col cols="4">
             <v-select
                      v-model="searchQuery"
                      :options="categoriesList"
                       label="type"
                      :reduce="val => val.id"
                       @input="getCoursesByCategory(searchQuery)"
                      placeholder="Select a category"
                    />
          </b-col>
  <b-col cols="4">
              <v-select
                      v-model="searchQueryCourse"
                      :options="coursesList"
                     label="name"
                      :reduce="val => val.id"
                      placeholder="Select a Course"
                    />
          </b-col>
          <b-col cols="4">     <b-form-input

            v-model="searchQueryCourseName"
            class="d-inline-block mr-1 "
            placeholder="Search Name..."
          /></b-col>

      
       
        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        striped
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="accreditationsList"
        :sort-by.sync="sort"
        show-empty
        empty-text="No matching records found"
      >

        <template #cell(actions)="data">
             <a>

            <router-link

              :to="`/update-course-partnership/${data.item.id}`"
              style="margin: 0px 12px"
            >
              <feather-icon icon="EditIcon" />
            </router-link>
          </a>

          <a
            v-if="$can('delete', 'accreditation')"
            role="button"
            @click="DeleteItem(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </a>
        </template>
      </b-table>
        <b-table
        id="sampletable"
        style="display: none;"

        class="position-relative"
        striped
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="accreditationsListAll"

        show-empty
        empty-text="No matching records found"
      >

      
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalaccreditations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, watch, computed, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import { useRouter } from '@core/utils/utils'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import 'flatpickr/dist/flatpickr.css'
import * as XLSX from 'xlsx/xlsx.mjs'
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,
    BDropdown,
    BDropdownItem,
    flatPickr,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,

    vSelect,
  },
  setup() {
    const accreditationsList = ref([])
     const accreditationsListAll = ref([])
        const date = ref('')
    const perPageOptions = [100, 200, 400, 600]
    const perPage = ref(100)
    const totalaccreditations = ref(0)
    const currentPage = ref(1)
    const refCourseListTable = ref('')
    const sort = ref('')
    const SortArray = []
    const searchQuery = ref('')
  

    const coursesList = ref([])
    const categoriesList = ref([])
store.dispatch('categories/AllCategory')
      .then(response => {
        categoriesList.value = response.data.data
      })
    const citiesList = ref([])

    const searchQueryCourseName = ref('')
    const searchQueryCourse = ref('')

  
    const getCoursesByCategory = id => {
       store
        .dispatch('categories/getCoursesByCategory', {
          'category_id': id,
        })
         .then(response => {
          response.data.data.forEach(el => {
            if (el.online == 1) {
              coursesList.value.push({
                id: el.id,
                name: `${el.name} Online`,
                link_id: el.link_id,
              })
            } else {
              coursesList.value.push({
                id: el.id,
                name: el.name,
                link_id: el.link_id,
              })
            }
          })
        })
    }

 
    const tableColumns = [
      { key: 'id', label: 'Id', sortable: true },
      { label: 'Course', key: 'course.name', sortable: true },
      { label: ' name', key: 'name', sortable: true },
      { key: 'link', key: 'link', sortable: true },

      { key: 'actions' },
    ]

    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
            perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalaccreditations.value,
      }
    })
    const meta = reactive({})

    const { route } = useRouter()
    const { id } = route.value.params
    const getaccreditations = () => {
      let data = null

      if (!SortArray.includes(sort.value) && sort.value != '') {
        SortArray.push(sort.value)
        data = SortArray.toString().replace('[', '')
      }
      
const queryParams = {
      
        'filter[course]':searchQueryCourse.value,
        'filter[name]':searchQueryCourseName.value,
        sort: data,
        per_page: perPage.value,
        page: currentPage.value,
      }
      store
        .dispatch('courses/allsponsors',  { ...queryParams })
        .then(response => {
       
console.log(response)
          accreditationsList.value = response.data
          console.log(accreditationsList.value)
          // totalaccreditations.value = response.data.data.total
        })
    }

    watch([currentPage, perPage, sort, searchQuery,date, searchQueryCourse,
      searchQueryCourseName], () => {
      getaccreditations()
    })
    getaccreditations()
    const DeleteItem = schduleId => {
      Vue.swal({
        icon: 'warning',
        title: 'Are You Sure?',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store
            .dispatch('courses/Deletesponsors',schduleId)
            .then(response => {
              getaccreditations()
              Vue.swal({
                title: ' Deleted ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
const save = () => {
   const wb = XLSX.utils.table_to_book(document.getElementById('sampletable'), {dateNF:'mm-dd-yyyy',cellDates:true, raw: true})
      XLSX.writeFile(wb, 'scheduleList.xlsx')
    }
    const fetchAllData = async () => {
      Vue.swal({
        icon: 'warning',
        title: 'Please wait until all the data is fetched.',
        confirmButtonText: 'YES',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
      const queryParams = {
        // "filter[search]": searchQuery.value,
        'filter[category]': searchQuery.value,
        'filter[course_name]': searchQueryCourseName.value,
       

        page: 1,
      }
      await store
        .dispatch('setting/allCountryPageScheduleCourse', { queryParams, id })
        .then(response => {
          accreditationsListAll.value = response.data
        }).then(() => {
          if (accreditationsListAll.value.length > 0) {
            save()
          }
        })
    }
    return {
         save,
      fetchAllData,
      searchQuery,
      date,
      tableColumns,
      accreditationsListAll,
      accreditationsList,
      DeleteItem,
      meta,
      getaccreditations,
      perPageOptions,
      totalaccreditations,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,
      SortArray,
      sort,
      id,
      searchQueryCourse,
      searchQueryCourseName,
      citiesList,
      coursesList,
      categoriesList,
      getCoursesByCategory,
    }
  },
}
</script>

  <style>
  .per-page-selector {
    width: 90px;
  }

  .dropdown-menu.show {
      height: 100px !important;
      overflow: auto !important;
  }
  </style>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
